<template>
   <div class="muro-list">
      <div class="muro-list-top">
         <SectionTitle
            :title="title_component.title"
            :subtitle="title_component.subtitle"
         />
         <div class="muro-list__searcher">
            <div class="muro-list-top-searcher-container">
               <input
                  type="text"
                  placeholder="Buscar posts"
                  v-model="search_post"
                  @keypress="searchPost()"
                  @keydown="handleBackspaceKey($event)"
               />
               <div class="icon">
                  <font-awesome-icon icon="search"></font-awesome-icon>
               </div>
            </div>
         </div>
      </div>
      <div class="muro-list__container">
         <ul class="muro-list__list" v-if="no_results == ''">
            <Draggable
               :animation="200"
               :list="posts"
               class="posts-list"
               ghost-class="ghost"
               @end="onDrop"
            >
               <li class="post" v-for="post in posts" :key="post.id_muro_ideas">
                  <div class="post-item user">
                     <div class="drag-icon">
                        <font-awesome-icon
                           icon="grip-vertical"
                        ></font-awesome-icon>
                     </div>
                     <div class="user-img">
                        <img :src="handleUserImg(post)" alt="" />
                     </div>
                     <div class="user-content">
                        <div class="name">{{ handleUserName(post) }}</div>
                        <div class="text">
                           {{ handleUserText(post.texto) }}
                        </div>
                     </div>
                  </div>
                  <div class="post-item options">
                     <div
                        class="fix"
                        :class="post.fijo ? 'fixed' : 'unfixed'"
                        @click="fixPost(post)"
                     >
                        <span
                           class="fix"
                           :content="
                              !post.fijo ? 'Fijar post' : 'Desfijar post'
                           "
                           v-tippy="{ arrow: true }"
                        >
                           <font-awesome-icon
                              icon="thumbtack"
                           ></font-awesome-icon>
                        </span>
                     </div>
                     <div class="edit" @click="openEditPost(post)">
                        <span
                           class="edit"
                           content="Editar post"
                           v-tippy="{ arrow: true }"
                        >
                           <font-awesome-icon icon="pen"></font-awesome-icon>
                        </span>
                     </div>
                     <div class="delete" @click="openDeletePost()">
                        <span
                           class="delete"
                           content="Eliminar post"
                           v-tippy="{ arrow: true }"
                        >
                           <font-awesome-icon
                              icon="trash-alt"
                           ></font-awesome-icon>
                        </span>
                     </div>
                  </div>
               </li>
            </Draggable>
         </ul>
      </div>
      <div class="no-results" v-if="no_results !== ''">
         {{ no_results }}
      </div>
      <!-- Modal para eliminar post -->
      <Delete
         v-if="open_modal_delete"
         title="Eliminar Post"
         msg="¿Estás seguro que deseas eliminar este post?"
         @cancel="cancelDelete"
         @delete="deletePost"
      />
      <!--  Modal que muestra el estatus de la accion (exito o error) -->
      <Status
         v-if="open_modal_status"
         :msg="modal_status_msg"
         :status="modal_status"
         @close="closeStatus"
      />
      <!-- Spinner -->
      <Spinner v-if="show_spinner" />
   </div>
</template>

<script>
import SectionTitle from "../Section/SectionTitle.vue";
import Draggable from "vuedraggable";
import Delete from "../Modales/Delete.vue";
import Status from "../Modales/Status.vue";
import Spinner from "../Spinner.vue";
import gql from "graphql-tag";

const GET_MUROS = gql`
   query muros($id_empresa: String!) {
      muros(id_empresa: $id_empresa) {
         id_muro_ideas
         texto
         usuario {
            id_usuario
            primer_nombre
            apellido_paterno
            imagen_perfil
         }
      }
   }
`;

export default {
   components: { SectionTitle, Draggable, Delete, Spinner, Status },
   data() {
      return {
         title_component: {
            title: "Posts",
            subtitle: "Posts publicados",
         },
         posts: [
            {
               id: 0,
               data: "post 0",
               fijo: true,
            },
            {
               id: 1,
               data: "post 1",
               fijo: false,
            },
            {
               id: 2,
               data: "post 2",
               fijo: false,
            },
            {
               id: 3,
               data: "post 3",
               fijo: false,
            },
            {
               id: 4,
               data: "post 4",
               fijo: false,
            },
            {
               id: 5,
               data: "post 5",
               fijo: false,
            },
         ],
         user_default_img: require("@/assets/img/user_default_img.svg"),
         open_modal_status: false,
         open_modal_delete: false,
         modal_status_msg: "",
         modal_status: false,
         show_spinner: false,
         search_post: "",
         no_results: "",
      };
   },
   mounted() {
      this.show_spinner = true;
      this.getPosts();
      this.show_spinner = false;
   },
   methods: {
      
      // Funcion para obtener los post del muro
      getPosts() {
         this.posts = [];
         this.$apollo
            .query({
               query: GET_MUROS,
               variables: { id_empresa: "1" },
            })
            .then((res) => {
               var resp = res.data.muros;
               console.log("resp", resp);
               for (let i = 0; i < resp.length; i++) {
                  resp[i].fijo = false;
                  this.posts.push(resp[i]);
               }
            })
            .catch((err) => {
               console.log("err", err);
            });
      },
      // Funcion que se encarga del nombre del usuario cuando el ws no devuelve un usuario
      handleUserName(post) {
         if (post.usuario && post.usuario.length > 0) {
            return (
               post.usuario[0].primer_nombre +
               " " +
               post.usuario[0].apellido_paterno
            );
         } else {
            return "Nombre Usuario";
         }
      },
      // Funcion que se encarga de la img del usuario si es que el ws no devuelve una
      handleUserImg(post) {
         let user = {};
         if (user.imagen_perfil && post.usuario != undefined) {
             user = post.usuario[0];
            return user.imagen_perfil;
         } else {
            return this.user_default_img;
         }
      },
      // Funcion que corta el texto del post cuando es mayor a 80 caracateres
      handleUserText(text) {
         if (text && text.length > 80) {
            return text.slice(0, 80);
         } else {
            return text;
         }
      },
      // Funcion que se ejecuta cuando se 'suelta' un item de la lista
      onDrop(dropResult) {
         console.log(dropResult);
      },
      // Abrir modal para eliminar post
      openDeletePost() {
         this.open_modal_delete = true;
      },
      // Funcion para cerrar modal de eliminar post
      cancelDelete() {
         this.open_modal_delete = false;
      },
      // Funcion para eliminar post
      deletePost() {
         this.open_modal_delete = false;
         this.modal_status_msg = "Post eliminado con éxito";
         this.modal_status = true;
         this.open_modal_status = true;
      },
      // Funcion para cerrar modal que muestra el estatus de la accion (exito o error)
      closeStatus() {
         this.open_modal_status = false;
      },
      // Funcion para fijar o desfijar un post
      fixPost(post) {
         var index = this.posts.findIndex(
            (elem) => elem.id_muro_ideas == post.id_muro_ideas
         );
         var selected_post = this.posts[index];
         if (post.fijo) {
            selected_post.fijo = false;
            this.modal_status_msg = "Post desfijado con éxito";
            this.modal_status = true;
            this.open_modal_status = true;
         } else {
            selected_post.fijo = true;
            this.modal_status_msg = "Post fijado con éxito";
            this.modal_status = true;
            this.open_modal_status = true;
         }
      },
      // Funcion para editar un album
      openEditPost(post) {
         console.log('post to edit',post);
         this.$router
            .push({
               name: "edit",
               params: { id: post.id_muro_ideas },
            })
      },
      // Funcion para buscar un post
      searchPost() {
         if (this.search_post.length > 2) {
            let search_result = this.posts.filter((post) =>
               post.texto.includes(this.search_post)
            );
            if (search_result.length > 0) {
               this.posts = [];
               for (let i = 0; i < search_result.length; i++) {
                  this.posts.push(search_result[i]);
               }
            } else {
               this.no_results = this.$noResults;
            }
         }
      },
      // Funcion para obtener la lista de post al borrar el input de busqueda
      handleBackspaceKey(e) {
         if (e.key === "Backspace" && this.search_post.length <= 2) {
            this.no_results = "";
            this.getPosts();
         }
      },
   },
};
</script>